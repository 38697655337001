module app.controllers.applicationcore {

    interface IIFrameStateParams extends ng.ui.IStateParamsService {
        url: string
    }

    interface ILogicDetailsURL {
        Username: string,
        Password: string,
        View: string
    }

    export class loginCtrl {

        static $inject = ['$scope', 'userAccountService', '$rootScope', 'generalService', '$cookies', '$http', '$location', 'descriptionService', 'bsLoadingOverlayService',
            '$state', '$stateParams', "$q", "ENV", 'entityService'];

        title: string;

        loginDetails: interfaces.applicationcore.ILoginDetails;

        forgotPassword: boolean;
        resetEmail: string;
        username: string;
        password: string;

        $urlParams: ng.ui.IUrlRouterService;

        loginDetailsURL: any;

        WindowsAuthentication: boolean = true;
        AzureAD: boolean = false;

        constructor(private $scope: ng.IScope, private userAccountService: interfaces.applicationcore.IUserAccountService,
            private $rootScope: interfaces.applicationcore.IRootScope, public generalService: interfaces.applicationcore.IGeneralService,
            private $cookies: ng.cookies.ICookiesService,
            private $http: ng.IHttpService, private $location: ng.ILocationService,
            private descriptionService: services.applicationcore.descriptionService, private bsLoadingOverlayService,
            public $state: ng.ui.IStateService,
            public $stateParams: IIFrameStateParams,
            private $q: ng.IQService,
            private ENV: interfaces.applicationcore.serverConfig,
            private entityService: interfaces.applicationcore.IEntityService
            ) {
            this.$cookies.remove("userToken");
            this.$cookies.remove("userFullName");

            this.$rootScope.isLoggedIn = false;
            this.$rootScope.userFullName = undefined;
            this.$rootScope.userToken = undefined;
            this.$rootScope.userId = undefined;
            this.$rootScope.userEmail = undefined;
            
            //this.$rootScope.showMenu = true;

            this.$http.defaults.headers.common["Authorization"] = undefined;

            this.WindowsAuthentication = this.ENV.WINDOWS_AUTHENTICATION;
            this.AzureAD = this.ENV.AZUREAD;

            this.forgotPassword = false;


            this.$urlParams = $location.search();

            this.loginDetailsURL = this.$urlParams;

            if (this.loginDetailsURL.View) {
                this.$rootScope.showMenu = false;
            }
            else
            {
                this.$rootScope.showMenu = true;
            }

            this.$cookies.remove("showMenu");
            this.$cookies.remove("sessionDoesNotExpire");
            this.$cookies.remove("styleSheet");
            
            /*
            angular.element('head').remove('link[src="templates/styles/lexisnexis.css"]');
            $('link[src="templates/styles/lexisnexis.css"]').remove();
            $('link[href="lexisnexis.css"]').remove();
            */         

            this.removeStyleSheets();

             if (this.loginDetailsURL && this.loginDetailsURL.Username && this.loginDetailsURL.Password && this.loginDetailsURL.View) {
                 this.loginDetails = { userName: this.loginDetailsURL.Username, password: this.loginDetailsURL.Password, passwordIsHashed: false, enumAuthenticationType: 1 };
                this.loginUser();
            }

            if (this.ENV.WINDOWS_AUTHENTICATION){
                this.$http.defaults.withCredentials = true;
                this.loginDetails = { userName: 'WinAuth', password: 'WinAuth', passwordIsHashed: false, enumAuthenticationType: 2 };
                this.loginUser();
            }
            else{
            }
        }

        removeStyleSheets()
        {
            var links = document.getElementsByClassName("injected");
            
            _.forEach(links, function (element) {
                element.parentNode.removeChild(element);
            });           
            var element = $('head').children('link[href="lexisnexis.css"]')[0];
            if (element){
                element.remove();
            }
        }

        formSubmit() {
            if (this.forgotPassword) {
                this.resetUserPassword();
            } else {
                this.loginDetails = { userName: this.username, password: this.password, passwordIsHashed: false, enumAuthenticationType: 1, token: "" };
                this.$http.defaults.headers.common["Authorization"] = undefined;
                this.loginUser();
            }
        }

        //Login button click function
        loginUser(): void {
            
            if (this.loginDetails) {

                this.removeStyleSheets();
                
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'user.login'
                }, () => {
                    delete this.$http.defaults.headers.common["Accept"];

                        if (this.loginDetails.enumAuthenticationType == 1)
                            return this.userAccountService.loginUser().save(this.loginDetails, (data) => { this.successLogin(data) }, (data) => { this.failureLogin(data) }).$promise;
                        if (this.loginDetails.enumAuthenticationType == 2)
                            return this.userAccountService.loginADUser().save(this.loginDetails,(data) => { this.successLogin(data) }, (data) => { this.failureLogin(data) }).$promise;
                        if (this.loginDetails.enumAuthenticationType == 3)
                            return this.userAccountService.loginAADUser().save(this.loginDetails, (data) => { this.successLogin(data) }, (data) => { this.failureLogin(data) }).$promise;
                });
            }
        };

        loginMSAL() {
            this.$state.go("azureAD");
        }

        successLogin(data: interfaces.applicationcore.IUser): void {
            if (data.Token) {

                this.$rootScope.isLoggedIn = true;
                this.$rootScope.userFullName = data.FirstName + " " + data.Surname;
                this.$rootScope.userId = data.UserId;
                this.$rootScope.sessionDoesNotExpire = false;
                this.$rootScope.userToken = data.Token;
                this.$rootScope.userEmail = data.Email;
                
                if (location.hostname === "localhost" || location.hostname === "127.0.0.1"  || location.protocol === "http:")
                {
                    this.$cookies.put("userFullName", this.$rootScope.userFullName);
                    this.$cookies.put("userId", this.$rootScope.userId);
                    this.$cookies.put("userToken", data.Token);
                }
                else
                {
                    this.$cookies.put("userFullName", this.$rootScope.userFullName,{samesite: "None",secure: true});
                    this.$cookies.put("userId", this.$rootScope.userId,{samesite: "None",secure: true});
                    this.$cookies.put("userToken", data.Token,{samesite: "None",secure: true});
                }


                this.$http.defaults.headers.common["Authorization"] = "Bearer " + this.$rootScope.userToken;

                this.loadDescriptions();

                var msgHandler = {
                    Messages: [{
                        Description: "",
                        Detail: "",
                        MessageType: 3,
                        MessageItems: [{
                            LocaleDescription: "Success",
                            TypeEnum: 3,
                            TypeId: 3
                        }]
                    }]
                };

                if (!this.loginDetailsURL.View) {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>msgHandler);
                }

                if (data.PasswordExpired) {
                    this.$state.go("changePassword");
                } else {
                    if (this.$rootScope.previousPath && this.$rootScope.previousPath !== "/login" && this.$rootScope.previousPath !== "/unauthorised") {
                        var previousPath = this.$rootScope.previousPath;
                        this.$rootScope.previousPath = null;
                        this.$location.url(previousPath);
                    } else {

                        var activeEntity = this.$cookies.get('activeEntity');
                        if (activeEntity){
                            
                            this.entityService.setUserEntity(Number(activeEntity)).save(
                                (msgHandler: interfaces.applicationcore.IMessageHandler) => {
                                    this.redirectAfterLogin();
                                },
                                (failureData) => {
                                    this.redirectAfterLogin();
                                });                           
                        }
                        else
                        {
                            this.redirectAfterLogin();
                        }


                    }
                }
            }
        }

        redirectAfterLogin() {
            if (this.loginDetailsURL.View) {

                this.$rootScope.showMenu = false;
                this.$rootScope.sessionDoesNotExpire = true;

                if (location.hostname === "localhost" || location.hostname === "127.0.0.1")
                {
                    this.$cookies.put("showMenu", String(this.$rootScope.showMenu));
                    this.$cookies.put("sessionDoesNotExpire", String(this.$rootScope.sessionDoesNotExpire));
                    this.$cookies.put("styleSheet", this.loginDetailsURL.StyleSheet);
                }
                else
                {
                    this.$cookies.put("showMenu", String(this.$rootScope.showMenu),{samesite: "None",secure: true});
                    this.$cookies.put("sessionDoesNotExpire", String(this.$rootScope.sessionDoesNotExpire),{samesite: "None",secure: true});
                    this.$cookies.put("styleSheet", this.loginDetailsURL.StyleSheet,{samesite: "None",secure: true});
                }
                if (this.loginDetailsURL.StyleSheet) {
                    //angular.element('head').append('<link href="templates/styles/' + this.loginDetailsURL.StyleSheet +'" rel="stylesheet" />');
                    var link = document.createElement('link');
                    link.className = "injected";
                    link.rel = "stylesheet";
                    link.href = "templates/styles/" + this.loginDetailsURL.StyleSheet; 
                    document.getElementsByTagName('head')[0].appendChild(link);
                }
                
                

                this.$state.go(this.loginDetailsURL.View);
            }
            else {                            
                this.$state.go("auth.Default");
            }
        }

        failureLogin(errorResponse): void {
            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
        }

        addDays(date, days) {
            var result = new Date(date);
            result.setDate(result.getDate() + days);
            return result;
        }

        loadDescriptions() {
            this.descriptionService.loadDescriptions();
        }

        forgotPasswordClick() {
            this.forgotPassword = true;
        }

        forgotPasswordBackClick() {
            this.forgotPassword = false;
        }

        resetUserPassword() {
            if (this.resetEmail) {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'user.login'
                }, () => {
                    return this.userAccountService.resetPassword().get({ email: this.resetEmail }, (result) => {
                        var messageHandler = {
                            ID: "0",
                            Messages: <interfaces.applicationcore.IMessage[]>[<interfaces.applicationcore.IMessage>{
                                MessageType: 1,
                                MessageItems: <interfaces.applicationcore.IMessageItem[]>[{
                                    LocaleDescription: "You should receive an email shortly containing instructions to reset your password. Please check your mailbox in a short while. ",
                                    TypeEnum: 3
                                }]
                            }]
                        }
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>messageHandler);

                        this.forgotPassword = false;
                    }).$promise;
                });
            }
            
        }
    };

    angular.module("app").controller("loginCtrl", controllers.applicationcore.loginCtrl);
}